import React from "react"
import Img from "gatsby-image"
import styles from './image.module.scss'

export default ({ localFile, url, contain = true, ...props }) => localFile
  ? <Img {...props} fadeIn={false} className={styles.globalImages} fluid={localFile.childImageSharp.sizes} contain={contain} />
  : <div class=" gatsby-image-wrapper" style={{ position: 'relative', overflow: 'hidden' }}>
       <picture>
          <img src={url} alt="" loading="lazy" style={{ width: '100%', height: '100%' }} />
       </picture>
    </div>
